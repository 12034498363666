import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { elementFunctions } from '../pages/Admin';
import { convertFromJSON } from '../pages/HomePage';

function FourthSlide({props}) {
    const [children, setChildren] = useState([]);
    const [selected, setSelected] = useState(0);
    
    useEffect(() => {
        axios.get(`https://liveclass.md:3001/components/byParent/${props.id}`).then((response) => {
            let data = convertFromJSON(response.data);
            setChildren(data);
        });
    }, [props]);

    const onClickButton = (id) => {
        for(let key in children){
            if(children[key].id === id){
                setSelected(key);
                break;
            }
        }

        if(window.innerWidth <= 600){
            for(let key in children){
                let el = document.getElementById(`switch_data_${children[key].id}`);
                el.style.display = "none";
            }

            let data = document.getElementById(`switch_data_${id}`);
            if(data){
                if(data.style.display === "none"){
                    data.style = {
                        display: "inline-block",
                        transition: "0.5s ease"
                    }
                } else {
                    data.style.display = "none";
                }
            }
        }
    }

    return (
        <div className='FourthSlide'>
            <div className='FourthSlideTitle'>
                <h1>{props.data.title}</h1>
            </div>
            <div className='FourthSlideInner'>
                <div className='FourthSlideFlex'>
                    <div className='FourthSlideChildren'>
                        {children.map((value, index) => {
                            const elKey = `fourthslide_${value.type}_${index}`;
                            if(elementFunctions[value.type]){
                                let elementFunction = elementFunctions[value.type];
                                return(
                                    <div id={value.id} key={elKey} className='FourthSlideChild' onClick={(e) => onClickButton(value.id)}>
                                        <elementFunction.func props={value}/>

                                        <div id={`switch_data_${value.id}`} className='SwitchButtonHidden' style={{
                                                display: "none",
                                            }}>
                                            <h5>{value.data.title}</h5>

                                            <p>{value.data.subtitle}</p>
                                        </div>
                                    </div>
                                )
                            } else return <div key={elKey} props={{}}></div>
                        })}
                    </div>
                </div>
            </div>

            {children.length > 0 && <div className='FourhtSlideChildrenData'>
                <div className='FourthSlideChildrenInner'>
                    <div className='ChildrenLeft'>
                        <div className='ChildrenTitle'>
                            <h5>{children[selected].data.title}</h5>
                        </div>

                        <div className='ChildrenSubtitle'>
                            <p>{children[selected].data.subtitle}</p>
                        </div>
                    </div>

                    <div className='ChildrenRight'>
                        <img src={`https://liveclass.md:3001/images/${children[selected].data.second_img}`} alt={""}></img>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default FourthSlide