import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { elementFunctions } from '../pages/Admin';
import { convertFromJSON } from '../pages/HomePage';

export default function SmallTextSlide({props}) {
    const [children, setChildren] = useState([]);
    
    useEffect(() => {
        axios.get(`https://liveclass.md:3001/components/byParent/${props.id}`).then((response) => {
            let data = convertFromJSON(response.data);
            setChildren(data);
        })
    }, [props]);

    return (
        <div className="SmallTextSlide">
            <div className='SmallTextSlideInner'>
                <h1 className='SmallTextSlideTitle'>
                    {props.data.title}
                </h1>

                <p className='SmallTextSlideSubtitle'>
                    {props.data.subtitle}
                </p>

                <div className='SmallTextSlideChildren'>
                    {children.map((value, index) => {
                        const elKey = `smalltextslide_${value.type}_${index}`;
                        if(elementFunctions[value.type]){
                            let elementFunction = elementFunctions[value.type];
                            return(
                                <div id={value.id} key={elKey} className='RecenziiSlideChild'>
                                    <elementFunction.func props={value}/>
                                </div>
                            )
                        } else return <div key={elKey} props={{}}></div>
                    })}
                </div>
            </div>
        </div>
    )
}
