import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

export default function ThankYou(){
    const ThankYouStyle = {
        width: "100vw",
        height: "100vh",

        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }

    let navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate("/");
        }, 5000);
    }, []);

    return(
        <div className={"ThankYou"} style={ThankYouStyle}>
            <div className={"ThankYouInner"} style={{display: "inline-block", width: "30%", height: "fit-content", textAlign: "center"}}>
                <h1>SUCCES!</h1>
                <p style={{textAlign: "center"}}>Foarte curând managerul tău te va contacta. Îți suntem recunoscători pentru încrederea oferită.</p>
                <img src={"check.png"} style={{width: "128px", height: "128px", marginTop: "1rem"}}/>
            </div>
        </div>
    )
}