import React from 'react';
import {useState} from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    if(localStorage.getItem("accessToken")){
        navigate("/admin");
    }
    
    const onSubmit = () => {
        const data = {
            username: username,
            password: password
        };

        axios.post("https://liveclass.md:3001/users/login", data).then((response) => {
            if(response.data.error){
                alert(response.data.error);
            } else {
                localStorage.setItem("accessToken", response.data);
                navigate("/admin");
            }
        });
    }

    return (
        <div className='Login'>
            <div className='LoginInner'>
                <input onChange={(e) => setUsername(e.target.value)} value={username} type="text" placeholder="Name"/>
                <input onChange={(e) => setPassword(e.target.value)} value={password} type="password" placeholder="Password"/>

                <button onClick={onSubmit} type="submit">Autentificare</button>
            </div>
        </div>
    )
}

export default Login;