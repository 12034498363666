import React from 'react';

function Board({props}) {
  return (
    <div className='Board'>
        <div className='BoardInner'>
            <div className='BoardIcon'>
              <img src={`https://liveclass.md:3001/images/${props.data.img}`} width={50} height={50} alt={""}></img>
            </div>

            <h4 className='BoardTitle'>
                {props.data.title}
            </h4>

            <p className='BoardSubtitle'>
                {props.data.subtitle}
            </p>
        </div>
    </div>
  )
}

export default Board;