import React from 'react'
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { available_elements } from './Admin';
import { useState } from 'react';

function NewComponent() {
    const [elementType, setType] = useState("");
    const [elementData, setData] = useState([]);

    const navigator = useNavigate();
    let params = useParams();
    let id = false;

    if(params && params.id){
        id = params.id;
    } else id = false;

    console.log(id);

    const UpdateType = (type) => {
        setType(type);
        setData([]);
        for(let key in available_elements){
            if(available_elements[key].type === type){
                setData(available_elements[key].data);
                break;
            }
        }
    }

    const UpdateData = (key, value, e) => {
        if(key.includes("img")){
            let image = e.target.files[0];
            let formData = new FormData();
            formData.append("file", image);

            axios.post("https://liveclass.md:3001/upload", formData).then(async(response) => {
                if(!response.data.error){
                    setData(elementData => ({
                        ...elementData,
                        [key]: image.name
                    }));
                }
            });
        } else {
            setData(elementData => ({
                ...elementData,
                [key]: value
            }));
        }
    }

    const CreateNewElement = () => {
        const data = {type: elementType, parent: id, data: JSON.stringify(elementData)}
        axios.post("https://liveclass.md:3001/components/new", data).then((response) => {
            console.log(response.data);
            navigator("/admin");
        });
    }
 
    const CloseNewElement = () => {
        navigator("/admin");
    }

    return (
        <div className='NewComponent'>
            <div className='Items'>
                <div className='Inputs'>
                    <label htmlFor="type">Tip: </label>
                    <select name="type" onChange={(e) => UpdateType(e.target.value)}>
                        <option value="">Type</option>
                        {available_elements.map((value, key) => {
                            return(
                                <option key={key} value={value.type}>
                                    {value.type}
                                </option>
                            )
                        })}
                    </select>

                    {Object.entries(elementData).map(([key, value]) => {
                        if(key.includes("img")){
                            return(
                                <div key={key} className="Input">
                                    <label htmlFor={key}>{key.charAt(0).toUpperCase() + key.slice(1)}</label>
                                    <input name={key} type="file" placeholder="Introduceti.." onChange={(e) => UpdateData(key, e.target.value, e)}></input>
                                </div>
                            )
                        } else {
                            return(
                                <div key={key} className="Input">
                                    <label htmlFor={key}>{key.charAt(0).toUpperCase() + key.slice(1)}</label>
                                    <input name={key} type="text" placeholder="Introduceti.." defaultValue={value} onChange={(e) => UpdateData(key, e.target.value)}></input>
                                </div>
                            )
                        }
                    })}
                </div>

                <div className='Buttons'>
                    <button onClick={CreateNewElement}>Creează</button>
                    <button onClick={CloseNewElement}>Înapoi</button>
                </div>
            </div>
        </div>
    )
}

export default NewComponent;