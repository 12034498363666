import React, { useEffect, useState } from 'react'
import axios from 'axios';

import { elementFunctions } from './Admin';

export function convertFromJSON(table){
    table.map((value, index) => {
        for(let key in value){
            if(key === "data"){
                table[index].data = JSON.parse(value.data)
            };
        };

        return(<></>)
    });

    return table;
}

function HomePage() {
    const [elements, setElements] = useState([]);

    useEffect(() => {
        axios.get("https://liveclass.md:3001/components").then((response) => {

            //CONVERT DIN JSON IN OBJECT
            response.data.map((value, index) => {
                for(let key in value){
                    if(key === "data"){
                        response.data[index].data = JSON.parse(value.data)
                    }
                }

                return(<></>)
            });

            setElements(response.data);
        });
    }, []);

    return (
        <div className='HomePage'>
            {elements.map((value, index) => {
                const elKey = `${value.id}_preview`;
                if(elementFunctions[value.type] && !value.parent){
                    let elementFunction = elementFunctions[value.type];
                    return(
                        <div id={elKey} key={elKey} className='PreviewElement'>
                            <elementFunction.func props={value}/>
                        </div>
                    )
                } else return <div id={elKey} key={elKey} props={{}}></div>
            })}
        </div>
    )
}

export default HomePage