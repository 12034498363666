export default function ProfesorBoard({props}){
    return(
        <div className="Profesor">
            <div className="ProfesorImage" style={{
                    backgroundImage: `url('https://liveclass.md:3001/images/${props.data.profesor_img}')`,
                    width: "100%",
                    height: "300px",
                    backgroundRepeat: "none",
                    backgroundSize: "cover"}}>

            </div>

            <div className="ProfesorInfo">
                <p>{props.data.nume}</p>
                <p>{props.data.descriere}</p>
            </div>
        </div>
    )
}