import React, {useEffect, useState} from "react";
import axios from "axios";
import {convertFromJSON} from "../pages/HomePage";
import {elementFunctions} from "../pages/Admin";

export default function WhiteTextSlide({props}){
    const [children, setChildren] = useState([]);

    useEffect(() => {
        axios.get(`https://liveclass.md:3001/components/byParent/${props.id}`).then((response) => {
            let data = convertFromJSON(response.data);
            setChildren(data);
        })
    }, [props]);

    return(
        <div className="WhiteTextSlide">
            <div className="Text">
                <p>{props.data.text}</p>
            </div>

            <div className="Children">
                {children.map((value, index) => {
                    const elKey = `whitetextslide_${value.type}_${index}`;
                    if(elementFunctions[value.type]){
                        let elementFunction = elementFunctions[value.type];
                        return(
                            <div id={value.id} key={elKey} className='WhiteTextSlideChild'>
                                <elementFunction.func props={value}/>
                            </div>
                        )
                    } else return <div key={elKey} props={{}}></div>
                })}
            </div>
        </div>
    )
}