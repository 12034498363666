import React from 'react'

function SecondaryButton({props}) {
  const onClick = () => {
    window.open(props.data.link);
  }

  if(props.data.link.length <= 0){
    return (
      <div className='SecondaryButton' style={{
        width: props.data.width,
        height: props.data.height,
      }}><a href={`#${props.data.anchor}`}>{props.data.text}</a></div>
    )
  } else {
    return (
      <div onClick={onClick} className='SecondaryButton' style={{
        width: props.data.width,
        height: props.data.height,
      }}>{props.data.text}</div>
    )
  }
}

export default SecondaryButton;