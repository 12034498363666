import React from 'react';

function Pachet({props}) {
    return (
        <div className='Pachet'>
            <div className='PachetTop'>
                <img src={`https://liveclass.md:3001/images/${props.data.iconita_img}`} alt={""}></img>
                <h3 className='Title'>{props.data.title}</h3>
                <div>
                    <p className='Subtitle'>{props.data.subtitle}</p>
                </div>
            </div>

            <div className='PachetInner'>
                <div className="PachetText">
                    <p>{props.data.text1}</p>
                </div>

                <div className="PachetText">
                    <p>{props.data.text2}</p>
                </div>

                <div className="PachetText">
                    <p>{props.data.text3}</p>
                </div>

                <div className="PachetText">
                    <p>{props.data.text4}</p>
                </div>

                <div className="PachetText">
                    <p>{props.data.text5}</p>
                </div>

                <div className="PachetText">
                    <p>{props.data.text6}</p>
                </div>

                <div className="PachetText">
                    <p>{props.data.text7}</p>
                </div>

                <div className="PachetText">
                    <p>{props.data.text8}</p>
                </div>

                <div className="PachetText">
                    <p>{props.data.text9}</p>
                </div>
            </div>

            <div className='PachetBottom'>
                <h3>{props.data.price}</h3>

                <div id="PachetButton" className="PrimaryButton">
                    <a href={`#${props.data.anchor}`}>{props.data.select}</a>
                </div>
            </div>
        </div>
    );
}

export default Pachet;