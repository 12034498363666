import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { elementFunctions } from '../pages/Admin';
import { convertFromJSON } from '../pages/HomePage';

function Nav({props}) {
    const [elementData, setElementData] = useState([])
    const [children, setChildren] = useState([]);
    
    useEffect(() => {
        setElementData(props.data);

        axios.get(`https://liveclass.md:3001/components/byParent/${props.id}`).then((response) => {
            let data = convertFromJSON(response.data);
            setChildren(data);
        })

        /*const elementChildren = getElementChilds(props.id);
        console.log(elementChildren)
        setChildren(elementChildren);*/
    }, [props]);

    return (
        <nav className='Nav'>
            <div className='NavTitle'>
                <img src={`https://liveclass.md:3001/images/${props.data.logo_img}`} alt={""}></img>
                <p>{elementData.title}</p>
            </div>

            <div className='NavChildren'>
                {children.map((value, index) => {
                    const elKey = `nav_${value.type}_${index}`;
                    if(elementFunctions[value.type]){
                        let elementFunction = elementFunctions[value.type];
                        return(
                            <div id={value.id} key={elKey} className='PreviewElement'>
                                <elementFunction.func props={value}/>
                            </div>
                        )
                    } else return <div key={elKey} props={{}}></div>
                })}
            </div>
        </nav>
    )
}

export default Nav;