import React from 'react';
import {useState} from "react";
import axios from "axios";

function RecoverPassword() {
    const [email, setEmail] = useState("");
    
    const onSubmit = () => {
        const data = {
            email: email,
        };

        axios.post("https://liveclass.md:3001/users/recover", data).then((response) => {
            /*if(response.data.error){
                alert(response.data.error);
            } else {
                sessionStorage.setItem("accessToken", response.data);
                navigate("/admin");
            }*/
        });
    }

    return (
        <div className='Login'>
            <div className='LoginInner'>
                <input onChange={(e) => setEmail(e.target.value)} value={email} type="text" placeholder="E-Mail"/>

                <button onClick={onSubmit} type="submit">Request Recover</button>
            </div>
        </div>
    )
}

export default RecoverPassword;