import React from 'react';

function Recenzie({props}) {
  return (
    <div className='Recenzie'>
        <div className='RecenzieText'>
            <div className='RecenzieInner'>
                <img src="icon-quote.png" alt={""}></img>
                <p>{props.data.recenzie}</p>
            </div>
        </div>

        <div className='RecenzieProfil'>
            <div className='ProfilImage'>
                <img src={`https://liveclass.md:3001/images/${props.data.profile_img}`} alt={""}></img>
            </div>

            <div className='ProfilText'>
                <p className='ProfilName'>{props.data.name}</p>
                <p className='ProfilDescription'>{props.data.description}</p>
            </div>
        </div>
    </div>
  )
}

export default Recenzie;