import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import {convertFromJSON} from "../pages/HomePage";
import {elementFunctions} from "../pages/Admin";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export default function ProfesoriSlide({props}){
    const [children, setChildren] = useState([]);
    const [elementIndex, setElementIndex] = useState(0);
    const [goodToGo, setGoodToGo] = useState(false);

    useEffect(() => {
        axios.get(`https://liveclass.md:3001/components/byParent/${props.id}`).then((response) => {
            let data = convertFromJSON(response.data);

            setChildren(data);
            setGoodToGo(true);
        })
    }, [props]);

    return(
        <div className="ProfesoriSlide">
            <div className="Title">
                <h2>{props.data.title}</h2>
            </div>

            <div className="Profesori">
                {window.innerWidth <= 600 ? <>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={0}
                        loop={false}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={false}
                        modules={[Pagination, Navigation]}
                        className="mySwiper"
                    >
                        {children.map((value, index) => {
                            const elKey = `profi_${value.type}_${index}`;
                            if(elementFunctions[value.type]){
                                let elementFunction = elementFunctions[value.type];
                                return(
                                    <SwiperSlide id={value.id} key={elKey} className='ProfesoriChild'>
                                        <elementFunction.func props={value}/>
                                    </SwiperSlide>
                                )
                            } else return <div key={elKey} props={{}}></div>
                        })}
                    </Swiper>
                </> : <>
                    <Swiper
                        slidesPerView={3}
                        spaceBetween={30}
                        loop={false}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper"
                    >
                        {children.map((value, index) => {
                            const elKey = `profi_${value.type}_${index}`;
                            if(elementFunctions[value.type]){
                                let elementFunction = elementFunctions[value.type];
                                return(
                                    <SwiperSlide id={value.id} key={elKey} className='ProfesoriChild'>
                                        <elementFunction.func props={value}/>
                                    </SwiperSlide>
                                )
                            } else return <div key={elKey} props={{}}></div>
                        })}
                    </Swiper>
                </>}
            </div>
        </div>
    )
}