import React from 'react'

function Footer({props}) {
    const onClick = (link) => {
        window.open(link);
      }

    return (
        <div className='Footer'>
            <div className='FooterLogo'>
                <div className='Logo'>
                    <img src={`https://liveclass.md:3001/images/${props.data.logo_img}`} alt={""}></img>
                </div>
            </div>

            <div className='Copyright'><p>{props.data.copyright}</p></div>

            <div className='FooterSocial'>
                <div className='FooterSocialInner'>
                    {props.data.youtube.length > 0 && <label onClick={(e) => onClick(props.data.youtube)}><img src={"youtube.png"} width={"26px"} height={"18px"} alt={""}></img></label>}
                    {props.data.facebook.length > 0 && <label onClick={(e) => onClick(props.data.facebook)}><img src={"facebook.png"} width={"22px"} height={"22px"} alt={""}></img></label>}
                    {props.data.instagram.length > 0 && <label onClick={(e) => onClick(props.data.instagram)}><img src={"instagram.png"} width={"22px"} height={"22px"} alt={""}></img></label>}
                    {props.data.tiktok.length > 0 && <label onClick={(e) => onClick(props.data.tiktok)}><img src={"tiktok.png"} width={"22px"} height={"22px"} alt={""}></img></label>}
                </div>
            </div>
        </div>
    );
}

export default Footer