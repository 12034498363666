import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { elementFunctions } from '../pages/Admin';
import { convertFromJSON } from '../pages/HomePage';

function RecenziiSlide({props}) {
    const [children, setChildren] = useState([]);
    
    useEffect(() => {
        axios.get(`https://liveclass.md:3001/components/byParent/${props.id}`).then((response) => {
            let data = convertFromJSON(response.data);
            setChildren(data);
        })
    }, [props]);

    return (
        <div className='RecenziiSlide'>
            <div className='RecenziiText'>
                <h1>{props.data.title}</h1>
                <p className='Subtitle'>{props.data.subtitle}</p>
            </div>

            <div className='RecenziiChildren'>
                {children.map((value, index) => {
                    const elKey = `recenziislide_${value.type}_${index}`;
                    if(elementFunctions[value.type]){
                        let elementFunction = elementFunctions[value.type];
                        return(
                            <div id={value.id} key={elKey} className='RecenziiSlideChild'>
                                <elementFunction.func props={value}/>
                            </div>
                        )
                    } else return <div key={elKey} props={{}}></div>
                })}
            </div>
        </div>
    )
}

export default RecenziiSlide;