import React from 'react';
import { useEffect, useRef } from 'react';

function ScriptElement({props}) {
    var divRef = useRef(null);

    useEffect(() => {
        if(divRef === null){
            return;
        }

        const doc = document.createRange().createContextualFragment(props.data.scriptBody);

        divRef.current.innerHTML = "";
        divRef.current.classList.add("ScriptElement");
        divRef.current.appendChild(doc);
    }, [props]);

    return(
        <div ref={divRef}>
            
        </div>
    )
}

export default ScriptElement