import {elementFunctions} from "../pages/Admin";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {convertFromJSON} from "../pages/HomePage";

export default function InfoSlide({props}){
    const [children, setChildren] = useState([]);

    useEffect(() => {
        axios.get(`https://liveclass.md:3001/components/byParent/${props.id}`).then((response) => {
            let data = convertFromJSON(response.data);

            setChildren(data);
        })
    }, [props]);

    return(
        <div className="InfoSlide">
            <div className="LeftPhoto" style={{
                backgroundImage: `url('https://liveclass.md:3001/images/${props.data.img}')`,
                backgroundRepeat: "none",
                backgroundSize: "cover",
            }}>

            </div>

            <div className="RightSection">
                <h1>{props.data.title}</h1>
                <p>{props.data.subtitle}</p>

                <div className="RightData">
                    {children.map((value, index) => {
                        const elKey = `infoslide_${value.type}_${index}`;
                        if(elementFunctions[value.type]){
                            let elementFunction = elementFunctions[value.type];
                            return(
                                <div id={value.id} key={elKey} className='InfoChildren'>
                                    <elementFunction.func props={value}/>
                                </div>
                            )
                        } else return <div key={elKey} props={{}}></div>
                    })}
                </div>
            </div>
        </div>
    )
}