import React from 'react'

function TextSlide({props}) {
  return (
    <div className='TextSlide'>
        <div className='TextSlideInner'>
            <h1 className='TextSlideTitle'>
                {props.data.title}
            </h1>

            <p className='TextSlideSubtitle'>
                {props.data.subtitle}
            </p>
        </div>
    </div>
  )
}

export default TextSlide;