export default function Info({props}){
    return(
        <div className="Info">
            <div className="InfoImage"style={{
                backgroundImage: `url('https://liveclass.md:3001/images/${props.data.info_img}')`,
                backgroundRepeat: "none",
                backgroundSize: "cover",
            }}>

            </div>

            <div className="InfoRight">
                <h3>{props.data.title}</h3>

                <p>{props.data.info}</p>
            </div>
        </div>
    )
}