import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// COMPONENTS
import Nav from '../components/Nav';
import PrimaryButton from '../components/PrimaryButton';
import ScriptElement from '../components/ScriptElement';
import SecondaryButton from '../components/SecondaryButton';

//IMAGES
import up_arrow from "../images/arrow_up.png";
import down_arrow from "../images/arrow_down.png";
import Header from '../components/Header';
import SecondSlide from '../components/SecondSlide';
import Board from '../components/Board';
import ThirdSlide from '../components/ThirdSlide';
import TextBoard from '../components/TextBoard';
import SwitchButton from '../components/SwitchButton';
import FourthSlide from '../components/FourthSlide';
import TextSlide from '../components/TextSlide';
import RecenziiSlide from '../components/RecenziiSlide';
import Recenzie from '../components/Recenzie';
import SmallTextSlide from '../components/SmallTextSlide';
import Footer from '../components/Footer';
import Pachet from '../components/Pachet';
import WhiteTextSlide from "../components/WhiteTextSlide";
import BlueTextSlide from "../components/BlueTextSlide";
import BlackTextSlide from "../components/BlackTextSlide";
import ProfesorBoard from "../components/ProfesorBoard";
import ProfesoriSlide from "../components/ProfesoriSlide";
import Info from "../components/Info";
import InfoSlide from "../components/InfoSlide";
export const available_elements = [
    {type: "Nav", data: {
        title: "Default Title",

        logo_img: "",
    }},

    {type: "Header", data: {
        title: "Default Title",
        subtitle: "Default Subtitle",

        img: "",
        video_link: "",
    }},

    {type: "SecondSlide", data: {
        title: "Default Title",
    }},

    {type: "ThirdSlide", data: {
        title: "Default Title",
    }},

    {type: "FourthSlide", data: {
        title: "Default Title",
    }},

    {type: "TextSlide", data: {
        title: "Default Title",
        subtitle: "Default Subtitle",
    }},

    {type: "RecenziiSlide", data: {
        title: "Default Title",
        subtitle: "Default Subtitle",
    }},

    {type: "SmallTextSlide", data: {
        title: "Default Title",
        subtitle: "Default Subtitle",
    }},

    {type: "WhiteTextSlide", data: {
        text: "Default Text",
    }},

    {type: "BlueTextSlide", data:{
       title: "Default Title",
       subtitle: "Default Subtitle",
    }},

    {type: "BlackTextSlide", data:{
       title: "Default Title",
       subtitle: "Default Subtitle",
    }},

    {type: "ProfesoriSlide", data:{
       title: "Default Title",
    }},

    {type: "ProfesorBoard", data:{
        profesor_img: "",

        nume: "Nume Prenume",
        descriere: "Descriere profesor",
    }},

    {type: "Footer", data: {
        logo_img: "",
        copyright: "",
        subtitle: "Default Subtitle",

        youtube: "",
        facebook: "",
        instagram: "",
        tiktok: "",
    }},

    {type: "Board", data: {
        title: "Default Title",
        subtitle: "Default subtitle",

        img: "",
    }},

    {type: "Recenzie", data: {
        recenzie: "Default Recenzie",
        name: "Default name",
        description: "Default description",

        profile_img: "",
    }},

    {type: "TextBoard", data: {
        title: "Default Title",
        subtitle: "Default subtitle",
    }},

    {type: "InfoSlide", data: {
        title: "Default Title",
        subtitle: "Default subtitle",

        img: "",
    }},

    {type: "Info", data: {
       title: "Default Title",
       info: "Default Info",

       info_img: "",
    }},

    {type: "PrimaryButton", data: {
        text: "Default Title",
        width: "205px",
        height: "55px",

        link: "",
        anchor: "",
    }},

    {type: "SecondaryButton", data: {
        text: "Default Title",
        width: "200px",
        height: "30px",

        link: "",
        anchor: "",
    }},

    {type: "SwitchButton", data: {
        description: "Descriere",
        title: "Titlu",
        subtitle: "Subtitlu (descriere 2)",

        selected_img: "",
        unselected_img: "",
        second_img: ""
    }},

    {type: "ScriptElement", data: {
        scriptBody: "",
    }},

    {type: "Pachet", data: {
        iconita_img: "",
        title: "",
        subtitle: "",

        text1: "",
        text2: "",
        text3: "",
        text4: "",
        text5: "",
        text6: "",
        text7: "",
        text8: "",
        text9: "",

        price: "",
        select: "",
        anchor: "",
    }},
]

export const elementFunctions = {
    "Nav": {func: Nav},
    "PrimaryButton": {func: PrimaryButton},
    "SecondaryButton": {func: SecondaryButton},
    "ScriptElement": {func: ScriptElement},
    "Header": {func: Header},
    "SecondSlide": {func: SecondSlide},
    "Board": {func: Board},
    "ThirdSlide": {func: ThirdSlide},
    "TextBoard": {func: TextBoard},
    "SwitchButton": {func: SwitchButton},
    "FourthSlide": {func: FourthSlide},
    "TextSlide": {func: TextSlide},
    "RecenziiSlide": {func: RecenziiSlide},
    "Recenzie": {func: Recenzie},
    "SmallTextSlide": {func: SmallTextSlide},
    "Footer": {func: Footer},
    "Pachet": {func: Pachet},
    "WhiteTextSlide": {func: WhiteTextSlide},
    "BlueTextSlide": {func: BlueTextSlide},
    "BlackTextSlide": {func: BlackTextSlide},
    "ProfesorBoard": {func: ProfesorBoard},
    "ProfesoriSlide": {func: ProfesoriSlide},
    "Info": {func: Info},
    "InfoSlide": {func: InfoSlide},
}

export var elementChilds = [];
export const getElementChilds = (ElementId) => {
    if(elementChilds[ElementId]) return elementChilds[ElementId];

    return [];
}

const expanded = {}

function Admin() {
    const [elements, setElements] = useState([]);
    const navigate = useNavigate();

    if(!localStorage.getItem("accessToken")){
        navigate("/");
    }

    useEffect(() => {
        axios.get("https://liveclass.md:3001/components").then((response) => {

            //CONVERT DIN JSON IN OBJECT
            response.data.map((value, index) => {
                for(let key in value){
                    if(key === "data"){
                        response.data[index].data = JSON.parse(value.data);
                    };
                };

                available_elements.map((element) => {
                    if(element.type === value.type){
                        for(let key in element.data){
                            if(!response.data[index].data[key]){
                                response.data[index].data[key] = "";
                            }
                        }
                    }

                    return true;
                });

                if(!value.parent){
                    elementChilds[value.id] = []
                    expanded[value.id] = true;
                }

                if(value.parent && elementChilds[value.parent]){
                    elementChilds[value.parent].push(value);
                }

                return true;
            });



            setElements(response.data);
        });
    }, []);

    const OpenNewComponent = (elId) => {
        if(!elId) return navigate(`/newcomponent`);
        navigate(`/newcomponent/${elId}`);
    }

    const UpdateElementData = async(elementId, key, value, e) => {
        if(key.includes("img") && e){
            let image = e.target.files[0];
            let formData = new FormData();
            formData.append("file", image);
            formData.append("name", `${elementId}_key`);

            await axios.post(`https://liveclass.md:3001/upload/${elementId}/${key}`, formData, {headers: {
                header1: "multipart/form-data",
            }}).then(async(response) => {
                if(!response.data.error){
                    for(let index in elements){
                        if(elements[index].id === elementId){
                            elements[index].data[key] = `${elementId}_${key}.${image.name.split(".")[1]}`;
            
                            setElements(elements);

                            await axios.post(`https://liveclass.md:3001/components/save/one/${elementId}`, elements[index]).then((response) => {
                        
                            });
                            break;
                        }
                    }
                }
            });
        } else {
            for(let index in elements){
                if(elements[index].id === elementId){
                    elements[index].data[key] = value;
    
                    setElements(elements);
                    break;
                }
            }
        }
    }

    const SaveComponents = () => {
        elements.map((value, index) => {
            axios.post(`https://liveclass.md:3001/components/save`, value).then((response) => {
                let menuParent = document.getElementById("menu");
                let lastChild = menuParent.lastChild;

                if(response.data.elID === parseInt(lastChild.id.replace("_menu", ""))){
                    window.location.reload(false);
                }
            });

            return true;
        })
    }

    const ExpandElement = (elementID) => {
        let expand_element = document.getElementById(elementID);
        let display = expand_element.style.display;
        if(display === "none"){
            expand_element.style.display = "block";
        } else expand_element.style.display = "none";
    }

    const MoveElement = (elementId, value) => {
        let menuParent = document.getElementById("menu");
        let previewParent = document.getElementById("preview");

        let menuChild = document.getElementById(`${elementId}_menu`);
        let previewChild = document.getElementById(`${elementId}_preview`);

        let previewChildren = previewParent.childNodes;
        let menuChildren = menuParent.childNodes;

        let menuChildIndex = Array.from(
            menuParent.childNodes
        ).indexOf(menuChild);

        previewParent.removeChild(previewChild);
        menuParent.removeChild(menuChild);

        let new_position = menuChildIndex + value;

        let prevPositionChild = menuChildren[new_position];
        console.log(prevPositionChild);

        let oldChildId = parseInt(prevPositionChild.id.replace("_menu", ""))

        previewParent.insertBefore(previewChild, previewChildren[new_position]);
        menuParent.insertBefore(menuChild, menuChildren[new_position]);

        elements.map((element, index) => {
            if(element.id === elementId){
                element.position = new_position;

                console.log("NEW POSITION " + new_position);
            }

            if(element.id === oldChildId){
                element.position = menuChildIndex;
                console.log("OLD CHILD POSITION " + menuChildIndex);
            }

            return true;
        })

        setElements(elements);

        previewChild = null;
        menuChild = null;
    }

    const removeComponent = (componentID, isParent) => {
        if(!isParent){
            const previewChild = document.getElementById(componentID);
            let menuChild = document.getElementById(`child_menu_${componentID}`);

            axios.post(`https://liveclass.md:3001/components/delete/${componentID}`).then((response) => {
                if(!response.data.error){
                    previewChild.remove();
                    menuChild.remove();
                }
            });
        } else {
            const previewComponent = document.getElementById(`${componentID}_preview`);
            const menuComponent = document.getElementById(`${componentID}_menu`);

            axios.post(`https://liveclass.md:3001/components/delete/${componentID}`).then((response) => {
                if(!response.data.error){
                    previewComponent.remove();
                    menuComponent.remove();
                }
            });
        }
    }

    return (
        <div className='Admin'>
            <div className='Menu'>
                <div className='title'>
                    <h1>Admin</h1>
                </div>

                <div className='Elements' id="menu">
                    {elements.map((value, index) => {
                        const elKey = `${value.id}_menu`;
                        const elId = value.id;

                        if(value.parent) return false;
                        let expandId = `expand_${value.id}`;
                        return(
                            <div id={elKey} key={elKey} className="Element">
                                <div className='ElementInner'>
                                    <div className='Title'>
                                        <p>{value.type}</p>
                                    </div>

                                    <div className='Buttons'>
                                        <img onClick={(e) => MoveElement(value.id, -1)} src={up_arrow} width={16} height={16} alt={""}></img>
                                        <img onClick={(e) => MoveElement(value.id, 1)} src={down_arrow} width={16} height={16} alt={""}></img>
                                        <button id="expand" onClick={(e) => ExpandElement(expandId)}>+</button>
                                        <button onClick={(e) => removeComponent(value.id, true)}>-</button>
                                    </div>
                                </div>

                                {expanded[value.id] && (<div id={expandId} className='ElementExpand' style={{display: "none"}}>
                                    <h5>Data</h5>
                                    {Object.entries(value.data).map(([key, data]) => {
                                        let expandKey = `expand_${key}}`

                                        if(key.includes("img")){
                                            return(
                                                <div key={expandKey} className="ElementData">
                                                    <label htmlFor=''>{key.charAt(0).toUpperCase() + key.slice(1)}</label>
                                                    <input onChange={(e) => UpdateElementData(value.id, key, e.target.value, e)} name={key} type="file"/>
                                                </div>
                                            )
                                        } else return(
                                            <div key={expandKey} className="ElementData">
                                                <label htmlFor=''>{key.charAt(0).toUpperCase() + key.slice(1)}</label>
                                                <input onChange={(e) => UpdateElementData(value.id, key, e.target.value)} name={key} placeholder="Valoare.." defaultValue={data}/>
                                            </div>
                                        )
                                    })}

                                    <div className='ElementChildren'>
                                        <h5>Elemente</h5>
                                        <div className='Children'>
                                            {elements.map((element, key) => {
                                                let child_id = element.id;
                                                if(element.parent && element.parent === elId){
                                                    return(
                                                        <div id={`child_menu_${child_id}`} key={`child_expand_${key}`}>
                                                            <div className="ChildExpandTitle">
                                                                <h5>{element.type}</h5>
                                                                <button onClick={(e) => removeComponent(child_id)}>-</button>
                                                            </div>
                                                            {Object.entries(element.data).map(([key, value]) => {
                                                                const expandKey = `child_expand_${key}_${value}`;
                                                                if(key.includes("img")){
                                                                    if(element.type === "Board"){
                                                                        return(
                                                                            <div key={expandKey} className="ElementData">
                                                                                <label htmlFor=''>{key.charAt(0).toUpperCase() + key.slice(1)}</label>
                                                                                <input onChange={(e) => UpdateElementData(child_id, key, e.target.value, e)} name={key} placeholder="Valoare.." type="file"/>
                                                                                <h5>Dimensiuni imagine: 50x50 px</h5>
                                                                            </div>
                                                                        )
                                                                    }
                                                                    else if(element.type === "Pachet"){
                                                                        return(
                                                                            <div key={expandKey} className="ElementData">
                                                                                <label htmlFor=''>{key.charAt(0).toUpperCase() + key.slice(1)}</label>
                                                                                <input onChange={(e) => UpdateElementData(child_id, key, e.target.value, e)} name={key} placeholder="Valoare.." type="file"/>
                                                                                <h5>Dimensiuni imagine: 64x64 px</h5>
                                                                            </div>
                                                                        )
                                                                    }
                                                                    else if(element.type === "SwitchButton"){
                                                                        if(key === "second_img"){
                                                                            return(
                                                                                <div key={expandKey} className="ElementData">
                                                                                    <label htmlFor=''>{key.charAt(0).toUpperCase() + key.slice(1)}</label>
                                                                                    <input onChange={(e) => UpdateElementData(child_id, key, e.target.value, e)} name={key} placeholder="Valoare.." type="file"/>
                                                                                    <br></br>
                                                                                    <h5>Dimensiuni imagine: 450x250 px</h5>
                                                                                </div>
                                                                            ) 
                                                                        } else {
                                                                            return(
                                                                                <div key={expandKey} className="ElementData">
                                                                                    <label htmlFor=''>{key.charAt(0).toUpperCase() + key.slice(1)}</label>
                                                                                    <input onChange={(e) => UpdateElementData(child_id, key, e.target.value, e)} name={key} placeholder="Valoare.." type="file"/>
                                                                                    <h5>Dimensiuni imagine: 60x60 px</h5>
                                                                                </div>
                                                                            ) 
                                                                        }
                                                                    }
                                                                    else return(
                                                                        <div key={expandKey} className="ElementData">
                                                                            <label htmlFor=''>{key.charAt(0).toUpperCase() + key.slice(1)}</label>
                                                                            <input onChange={(e) => UpdateElementData(child_id, key, e.target.value, e)} name={key} placeholder="Valoare.." type="file"/>
                                                                        </div>
                                                                    )
                                                                } else if(key.includes("anchor")){
                                                                    return(
                                                                        <div key={expandKey} className="ElementData">
                                                                            <label htmlFor=''>{key.charAt(0).toUpperCase() + key.slice(1)}</label>
                                                                            <select onChange={(e) => UpdateElementData(child_id, key, e.target.value, e)} name="anchor_select" id="anchor_select">
                                                                                {elements.map((value, index) => {
                                                                                    if(value.parent === 0){
                                                                                        return(
                                                                                            <option key={`option_${index}`} value={`${value.id}_preview`}>{value.type}</option>
                                                                                        )
                                                                                    }

                                                                                    return true;
                                                                                })}
                                                                            </select>
                                                                        </div>
                                                                    )}
                                                                else {
                                                                    return(
                                                                        <div key={expandKey} className="ElementData">
                                                                            <label htmlFor=''>{key.charAt(0).toUpperCase() + key.slice(1)}</label>
                                                                            <input onChange={(e) => UpdateElementData(child_id, key, e.target.value)} name={key} placeholder="Valoare.." defaultValue={value}/>
                                                                        </div>
                                                                    )
                                                                }
                                                            })}
                                                        </div>
                                                    )
                                                }

                                                return true;
                                            })}
                                        </div>
                                        <button onClick={(e) => OpenNewComponent(elId) }>Adaugă component</button>
                                    </div>
                                </div>)}
                            </div>
                        )
                    })}
                </div>

                <div className='Buttons'>
                    <button onClick={() => OpenNewComponent()}>Adaugă component</button>
                    <button onClick={SaveComponents}>Salvează modificări</button>
                </div>
            </div>

            <div className='Preview' id="preview">
                {elements.map((value, index) => {
                    const elKey = `${value.id}_preview`;

                    if(elementFunctions[value.type] && !value.parent){
                        let elementFunction = elementFunctions[value.type];
                        return(
                            <div id={elKey} key={elKey} className='PreviewElement'>
                                <elementFunction.func props={value}/>
                            </div>
                        )
                    } else return true;
                })}
            </div>
        </div>
    );
}

export default Admin