import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";

import Login from './pages/Login';
import Admin from './pages/Admin';
import NewComponent from './pages/NewComponent';
import HomePage from "./pages/HomePage";
import RecoverPassword from './pages/RecoverPassword';
import ThankYou from "./pages/ThankYou";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<HomePage/>}/>
          <Route path="/thankyou" exact element={<ThankYou/>}/>
          <Route path="/login" exact element={<Login/>}/>
          <Route path="/admin" exact element={<Admin/>}/>
          <Route path="/recover" exact element={<RecoverPassword/>}/>
          <Route path="/newcomponent" exact element={<NewComponent/>}/>
          <Route path="/newcomponent/:id" exact element={<NewComponent/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
