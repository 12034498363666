import React from 'react';

function SwitchButton({props}) {
  return (
    <div className='SwitchButton'>
        <div className='SwitchIcon'>
            <img src={`https://liveclass.md:3001/images/${props.data.unselected_img}`} width={60} height={60} alt={""}></img>
        </div>
        
        <div className="SwitchDescription">
            {props.data.description}
        </div>
    </div>
  )
}

export default SwitchButton