import React from 'react'
import {useState, useEffect, useRef} from "react";
import axios from 'axios';
import { elementFunctions } from '../pages/Admin';
import { convertFromJSON } from '../pages/HomePage';

function Header({props}) {
    const [elementData, setElementData] = useState([])
    const [children, setChildren] = useState([]);
    var divRef = useRef(null);
    
    useEffect(() => {
        setElementData(props.data);

        axios.get(`https://liveclass.md:3001/components/byParent/${props.id}`).then((response) => {
            let data = convertFromJSON(response.data);
            setChildren(data);
        });

        if(divRef == null){
            return;
        }

        const doc = document.createRange().createContextualFragment(props.data.video_link);

        divRef.current.innerHTML = "";
        divRef.current.classList.add("ScriptElement");
        divRef.current.appendChild(doc);
    }, [props]);

    return (
        <div className='Header'>
            <div className="HeaderLeft">
                <div className='HeaderLeftInner'>
                    <div className="HeaderTitle">
                        <h1>{elementData.title}</h1>
                    </div>

                    <div className="HeaderSubtitle">
                        <p>{elementData.subtitle}</p>
                    </div>

                    <div className="HeaderElements">
                        {children.map((value, index) => {
                            const elKey = `header_${value.type}_${index}`;
                            if(elementFunctions[value.type]){
                                let elementFunction = elementFunctions[value.type];
                                return(
                                    <div id={value.id} key={elKey} className='PreviewElement'>
                                        <elementFunction.func props={value}/>
                                    </div>
                                )
                            } else return <div key={elKey} props={{}}></div>
                        })}
                    </div>
                </div>
            </div>

            <div className="HeaderRight">
                {props.data.video_link.length > 0 ? <div ref={divRef} className='HeaderImage' style={{
                    width: "100%",
                    height: "100%",
                }}>
                </div> : <div ref={divRef} className='HeaderImage' style={{
                        backgroundImage: `url('https://liveclass.md:3001/images/${props.data.img}')`,
                        width: "100%",
                        height: "100%",
                        backgroundRepeat: "none",
                        backgroundSize: "cover",
                    }}></div>}
                </div>
        </div>
    )
}

export default Header;