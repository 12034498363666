import React from 'react'

function TextBoard({props}) {
  return (
    <div className='TextBoard'>
        <p className='Title'>{props.data.title}</p>
        <p className='Subtitle'>{props.data.subtitle}</p>
    </div>
  )
}

export default TextBoard