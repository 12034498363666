import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { elementFunctions } from '../pages/Admin';
import { convertFromJSON } from '../pages/HomePage';

function ThirdSlide({props}) {
    const [children, setChildren] = useState([]);

    useEffect(() => {
        axios.get(`https://liveclass.md:3001/components/byParent/${props.id}`).then((response) => {
            let data = convertFromJSON(response.data);

            setChildren(data);
        })
    }, [props]);

    return (
        <div className='ThirdSlide'>
            <div>
                <h2 className='ThirdSlideTitle'>{props.data.title}</h2>
            </div>

            <div className='ThirdSlideFlex'>
                <div className='ThirdSlideInner'>
                    <div className='ThirdSlideChildren'>
                        {children.map((value, index) => {
                            const elKey = `thirdslide_${value.type}_${index}`;
                            if(elementFunctions[value.type]){
                                let elementFunction = elementFunctions[value.type];
                                return(
                                    <div id={value.id} key={elKey} className='Children'>
                                        <elementFunction.func props={value}/>
                                    </div>
                                )
                            } else return <div key={elKey} props={{}}></div>
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ThirdSlide